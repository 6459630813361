import React from 'react';

// components
import Event from './Event';

// styles
import styles from '../css/components/EventList.module.css';

export default class EventList extends React.Component {
  render() {
    const year = this.props.year;
    const eventsData = this.props.events;

    const eventsDataSorted = eventsData
      .sort(function(a, b) {
        return new Date(a.startDate) - new Date(b.startDate);
      })
      .reverse();

    const events = eventsDataSorted.map(event => <Event key={event.id} event={event} />);

    return (
      <section className={styles.eventList}>
        <div className="ta-center">
          <h2 className={styles.year}>{year}</h2>
        </div>
        {events}
      </section>
    );
  }
}
