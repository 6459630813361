import React from 'react';
import { graphql } from 'gatsby';

// components
import Container from '../components/Container';
import EventList from '../components/EventList';

// styles
import styles from '../css/pages/events.module.css';

export default ({ data, location }) => {
  const eventsData = data.allDatoCmsSchedule.edges;
  const events = eventsData.map(edge => (
    <EventList key={edge.node.id} year={edge.node.year} events={edge.node.events} />
  ));

  return (
    <Container title="Markety" permalink={location.pathname}>
      <div className="wrap-xl">
        <div className={styles.events}>{events}</div>
      </div>
    </Container>
  );
};

export const query = graphql`
  query {
    allDatoCmsSchedule(filter: { locale: { eq: "cs" } }, sort: { fields: year, order: DESC }) {
      edges {
        node {
          id
          year
          events {
            id
            name
            location
            startDate
            endDate
            urlLink
            descriptionNode {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`;
